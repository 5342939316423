














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
})
export default class Home extends Vue {
  private win = window;
  private autoplaycarousel = true;
  private slide2 = "1";
  private slide = 0;
  private slidePopUp = 0;
  private horizontalPercentageInteres = 0;
  private horizontalPercentageTurismo = 0;
  private aBanners: Array<dataBanner> = [];
  private noticias: Array<any> = [];
  private actividades: Array<any> = [];
  private empleos: Array<any> = [];
  private bannerSlide = 0;
  private galeria: Array<any> = [];
  private servicios: Array<any> = [];
  private popUp: any = null;
  private verPopUp = false;
  private verPopUpMultiple = false;
  private verModal: boolean = false;
  private ofertasData: any = null;
  private dataDialog: boolean = false;
  private slideIndex: number = 1;
  private currentPage = 1;
  private total = 0;
  private videosfb: Array<any> = [];
  private extraida: string = "";
  private catTurismo: Array<any> = [
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-que-hacer.png",
      nombre: "¿Qué hacer?",
      nombre_web: "que-hacer",
    },
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-donde-dormir.png",
      nombre: "¿Dónde dormir?",
      nombre_web: "donde-dormir-1",
    },
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-guias.png",
      nombre: "Guía de turismo",
      nombre_web: "guia-de-turismo-1",
    },
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-sugerencias.png",
      nombre: "¿Dónde comer?",
      nombre_web: "donde-comer-1",
    },
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-terrestre.png",
      nombre: "Actividades terrestres",
      nombre_web: "actividades-terrestres-1",
    },
    {
      img:
        "https://docs.munijf.cl/web/media/imagenes/2023/03/turismo-nautico.png",
      nombre: "Actividades náuticas",
      nombre_web: "actividades-nauticas",
    },
  ];

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getGaleria();
    }
  }

  private mounted() {
    //Agrega nuevamente el script de twitter para que se muestre el plugin
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(recaptchaScript);

    //Agrega nuevamente el script de instagram para que se muestre el plugin
    let recaptchaScriptInsta = document.createElement("script");
    recaptchaScriptInsta.setAttribute(
      "src",
      "https://cdn.lightwidget.com/widgets/lightwidget.js"
    );
    document.head.appendChild(recaptchaScriptInsta);

    let recaptchaScriptFb = document.createElement("script");
    recaptchaScriptFb.setAttribute(
      "src",
      "https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v15.0"
    );
    document.head.appendChild(recaptchaScriptFb);

    this.getBanners();
    this.getNoticias();
    this.getPopUp();
    // this.getNoticiasFb();
    this.getActividades();
    // this.getEmpleos();
    this.autoScrollIconos();
    this.autoScrollTurismo();
    this.autoscroll();

    // this.autoScrollIconos();
    this.getGaleria();
    this.getServicios();
  }
  private updated() {
    this.showSlides(this.slideIndex);
  }

  private toApp(link) {
    if (link != "#") {
      window.open(link, "_blank");
    }
  }

  private getBanners() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("slider")
      .then((res) => {
        // console.log(res.data);
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }

  private getActividades() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("actividades/destacadas")
      .then((res) => {
        // console.log(res.data);
        let base = process.env.VUE_APP_BASE_URL;
        if (this.$q.screen.xs || this.$q.screen.sm) {
          if (res.data.length >= 3) {
            for (let i = 0; i < 2; i++) {
              this.actividades.push(res.data[i]);
            }
          } else {
            this.actividades = res.data;
          }
        } else {
          this.actividades = res.data;
        }
        for (let i = 0; i < this.actividades.length; i++) {
          if (this.actividades[i].foto && this.actividades[i].foto != "") {
            this.actividades[i].foto = base + this.actividades[i].foto;
          } else {
            this.actividades[i].foto = require("@/assets/logos/logo300.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  // private getEmpleos() {
  //   this.$q.loading.show({
  //     delay: 100, // ms
  //   });
  //   this.$axios
  //     .get("empleos/destacados")
  //     .then((res) => {
  //       this.empleos = res.data;
  //       this.$q.loading.hide();
  //     })
  //     .catch((err: any) => {
  //       this.$q.loading.hide();
  //       console.log(err);
  //     });
  // }

  private getPopUp() {
    this.$axios
      .get("popup")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;

        this.popUp = res.data;
        for (let i = 0; i < this.popUp.length; i++) {
          if (this.popUp[i].imagen != "") {
            this.popUp[i].imagen = base + this.popUp[i].imagen;
          } else {
            this.popUp[i].imagen = require("@/assets/logos/logomuni-color.png");
          }
        }
        if (this.popUp.length == 1) {
          this.verPopUp = true;
          this.verPopUpMultiple = false;
        } else {
          this.verPopUp = false;
          this.verPopUpMultiple = true;
        }
      })
      .catch((err: any) => {
        this.popUp = [];
        this.verPopUp = false;
        this.verPopUpMultiple = false;
      });
  }

  // NOTICIAS
  private getNoticias() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("noticias/destacadas")
      .then((res) => {
        // console.log(res.data);
        // console.log(res);
        // console.log("noticias destacas", res.data);
        let base = process.env.VUE_APP_BASE_URL;
        // let base = "https://docs.quilpue.cl/";

        if (this.$q.screen.xs || this.$q.screen.sm) {
          for (let i = 0; i < 2; i++) {
            this.noticias.push(res.data[i]);
            // console.log(this.noticias);
          }
        } else {
          this.noticias = res.data;
        }
        for (let i = 0; i < this.noticias.length; i++) {
          if (
            this.noticias[i].fotos.image_intro &&
            this.noticias[i].fotos.image_intro != ""
          ) {
            this.noticias[i].fotos.image_intro =
              base + this.noticias[i].fotos.image_intro;
          } else {
            this.noticias[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.png");
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private clickPopUp(popUp) {
    if (popUp && popUp.url && popUp.url != "") {
      window.open(popUp.url, "_self");
    }
  }

  private verOferta(item: any) {
    this.ofertasData = item;
    this.dataDialog = true;
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irActividad(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionAct" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionAct",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoriaActividad(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CategoriaActividad" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CategoriaActividad",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }

  private fechaPublicacionNoticias(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }

  //   private fechaPublicacionNoticias(fechaInicio: any) {
  //   console.log("FechaInicio",fechaInicio);
  //   const anhoInicio = fechaInicio.substring(0, 4);
  //   let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
  //   const diaInicio = fechaInicio.substring(8, 10);
  //   const hora = fechaInicio.substring(11, 16);

  //   if (mesInicio == 1) {
  //     mesInicio = "enero";
  //   } else if (mesInicio == 2) {
  //     mesInicio = "febrero";
  //   } else if (mesInicio == 3) {
  //     mesInicio = "marzo";
  //   } else if (mesInicio == 4) {
  //     mesInicio = "abril";
  //   } else if (mesInicio == 5) {
  //     mesInicio = "mayo";
  //   } else if (mesInicio == 6) {
  //     mesInicio = "junio";
  //   } else if (mesInicio == 7) {
  //     mesInicio = "julio";
  //   } else if (mesInicio == 8) {
  //     mesInicio = "agosto";
  //   } else if (mesInicio == 9) {
  //     mesInicio = "septiembre";
  //   } else if (mesInicio == 10) {
  //     mesInicio = "octubre";
  //   } else if (mesInicio == 11) {
  //     mesInicio = "noviembre";
  //   } else {
  //     mesInicio = "diciembre";
  //   }

  //   if (mesInicio != 0) {
  //     return (
  //       diaInicio + " de " + mesInicio + " " + anhoInicio + " a las " + hora
  //     );
  //   }
  // }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
  private routerGoMenu(name: string, nombre_web: string) {
    if (nombre_web && nombre_web != "") {
      if (
        this.$router.currentRoute.name == name &&
        this.$router.currentRoute.params.nombre &&
        this.$router.currentRoute.params.nombre == nombre_web
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == name) {
          if (
            this.$router.currentRoute.params.nombre &&
            this.$router.currentRoute.params.nombre != nombre_web
          ) {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch((err) => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({ name: name, params: { nombre: nombre_web } })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({ name: name, params: { nombre: nombre_web } })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }

  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }
  // Fin Funciones del banner

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }

  // SCROLL CARROUSEL GALERIA
  // private scroll(direccion: string) {

  //   if (this.$refs.scrollInteres as any) {
  //     const size = (this.$refs.scrollInteres as any).scrollSize;
  //     const position = (this.$refs.scrollInteres as any).scrollPosition;
  //        console.log("position", position);

  //     if (direccion == "derecha") {

  //       if (this.horizontalPercentageInteres >= 0.9) {
  //         (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
  //       } else {
  //         (this.$refs.scrollInteres as any).setScrollPosition(
  //           position + 230,
  //           150
  //         );
  //       }
  //     } else {
  //       if (position - 90 < 0) {
  //         if (position > 0) {
  //           (this.$refs.scrollInteres as any).setScrollPosition(0, 180);
  //         } else {
  //           (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
  //         }
  //       } else {
  //         (this.$refs.scrollInteres as any).setScrollPosition(
  //           position - 230,
  //           150
  //         );
  //       }
  //     }
  //   }
  // }

  private scroll(direccion: string) {
    if (this.$refs.scrollInteres as any) {
      const size = (this.$refs.scrollInteres as any).scrollSize;
      const position = (this.$refs.scrollInteres as any).scrollPosition;

      if (direccion == "derecha") {
        if (this.horizontalPercentageInteres >= 0.99) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 120);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position + 90,
            120
          );
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollInteres as any).setScrollPosition(0, 120);
          } else {
            (this.$refs.scrollInteres as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(
            position - 90,
            120
          );
        }
      }
    }
  }

  private autoScrollIconos() {
    this.scroll("derecha");
    setTimeout(this.autoScrollIconos, 5000);
  }

  //INICIO SCROLL TURISMO
  private scrollTurismo(direccion: string) {
    // console.log(this.$refs.scrollInteres);
    if (this.$refs.scrollTurismo as any) {
      const size = (this.$refs.scrollTurismo as any).scrollSize;
      const position = (this.$refs.scrollTurismo as any).scrollPosition;
      // console.log("size", size);
      // console.log("position", position);

      if (direccion == "derecha") {
        // console.log("der horizontalporcentage ", this.horizontalPercentageTurismo);
        // llega al final con 0.5
        if (
          (this.$q.screen.xs || this.$q.screen.sm || this.$q.screen.md) &&
          this.horizontalPercentageTurismo >= 0.9
        ) {
          (this.$refs.scrollTurismo as any).setScrollPosition(0, 180);
        } else if (
          (this.$q.screen.lg || this.$q.screen.xl) &&
          this.horizontalPercentageTurismo >= 1
        ) {
          (this.$refs.scrollTurismo as any).setScrollPosition(0, 180);
        } else {
          (this.$refs.scrollTurismo as any).setScrollPosition(
            position + 230,
            150
          );
        }
      } else {
        // console.log("izq horizontalporcentage ", this.horizontalPercentageTurismo);
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollTurismo as any).setScrollPosition(0, 180);
          } else {
            (this.$refs.scrollTurismo as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollTurismo as any).setScrollPosition(
            position - 230,
            150
          );
        }
      }
    }
  }
  private scrollAreaFunctionTurismo(info: any) {
    this.horizontalPercentageTurismo = info.horizontalPercentage;
  }
  private autoScrollTurismo() {
    this.scrollTurismo("derecha");
    setTimeout(this.autoScrollTurismo, 5000);
  }

  // FIN SCROLL TURISMO

  private getGaleria() {
    this.$axios
      .get("galeria/home")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.galeria = res.data;
        // console.log(this.galeria);
        for (let i = 0; i < this.galeria.length; i++) {
          if (
            this.galeria[i].imagen_previa &&
            this.galeria[i].imagen_previa != ""
          ) {
            this.galeria[i].imagen_previa =
              base + this.galeria[i].imagen_previa;
            this.galeria[i].imagen_previa;
          } else {
            this.galeria[
              i
            ].imagen_previa = require("@/assets/logos/logo300.png");
          }
        }
        this.total = res.data.total;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private formatFechaCard(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private irGaleria(nombre_web: any) {
    if (
      this.$router.currentRoute.name == "DetalleGaleria" &&
      this.$router.currentRoute.params.nombre == nombre_web
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "DetalleGaleria",
          params: { nombre: nombre_web },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private getServicios() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      // .get("/anuncios")
      .get("/servicios/general?tipo=servicios")
      .then((res: any) => {
        // console.log(res);
        let servicio = res.data;
        for (let i = 0; i < servicio.length; i++) {
          for (let j = 0; j < servicio[i].servicios.length; j++) {
            // console.log(this.servicios[i].servicios[j]);
            this.servicios.push(servicio[i].servicios[j]);
          }
        }
        // console.log(this.servicios);
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  // private getServicios() {
  //   this.$q.loading.show({
  //     delay: 100 // ms
  //   });
  //   this.$axios
  //     .get("servicios/general?tipo=servicios")
  //     .then((res: any) => {
  //       let base = process.env.VUE_APP_BASE_URL;
  //       this.servicios = res.data;
  //       for (let i = 0; i < this.servicios.length; i++) {
  //         this.servicios[i].foto = base + this.servicios[i].foto;
  //         for (let j = 0; j < this.servicios[i].servicios.length; j++) {
  //           this.servicios[i].servicios[j].foto =
  //             base + this.servicios[i].servicios[j].foto;
  //         }
  //       }
  //       if (this.$route.query.option && this.$route.query.option != "") {
  //         for (let i = 0; i < this.servicios.length; i++) {
  //           if (this.servicios[i].nombre_web == this.$route.query.option) {
  //             this.selectedCategoria = this.servicios[i];
  //             this.verModal = true;
  //           }
  //         }
  //       }
  //       this.$q.loading.hide();
  //     })
  //     .catch((err: any) => {
  //       this.$q.loading.hide();
  //       console.log(err);
  //       // this.routerGo("Home");
  //     });
  // }
}
